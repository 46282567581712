import styled from "styled-components";

import { IExternalLinkProps } from "./external-link.interfaces";

import rem from "../../utils/rem";

const ExternalLink = styled.a<IExternalLinkProps>`
  display: inline-flex;
  align-items: center;
  color: ${({ theme, $color }) =>
    $color === "primary"
      ? theme.colors.primary.base
      : $color === "secondary"
      ? theme.colors.secondary.base
      : $color === "accent"
      ? theme.colors.accent.base
      : $color === "grey"
      ? theme.colors.grey.base
      : $color === "blue"
      ? theme.colors.blue.base
      : $color === "success"
      ? theme.colors.success.base
      : $color === "warning"
      ? theme.colors.warning.base
      : $color || theme.colors.success.base};
  font-size: ${rem(14)};
  cursor: pointer;

  > svg {
    color: ${({ theme, iconColor }) => iconColor || theme.colors.grey[600]};
    margin-left: 8px;
  }
`;

export default ExternalLink;

import { Fragment, PropsWithChildren } from "react";

import dayjs from "dayjs";
import dayjsTimezone from "dayjs/plugin/timezone";
import dayjsUtc from "dayjs/plugin/utc";
import dayjsParseString from "dayjs/plugin/customParseFormat";
import dayjsIsBetween from "dayjs/plugin/isBetween";
import dayjsSameOrBefore from "dayjs/plugin/isSameOrBefore";
import dayjsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import dayjsDuration from "dayjs/plugin/duration";

dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsParseString);
dayjs.extend(dayjsIsBetween);
dayjs.extend(dayjsSameOrBefore);
dayjs.extend(dayjsSameOrAfter);
dayjs.extend(dayjsDuration);

const DayjsProvider = ({ children }: PropsWithChildren) => {
  return <Fragment>{children}</Fragment>;
};

export default DayjsProvider;

import styled from "styled-components";

import { IDescriptionProps } from "./description.interfaces";

import rem from "../../utils/rem";

const Description = styled.p<IDescriptionProps>`
  color: ${({ $color, theme }) => $color || theme.colors.grey[650]};
  font-weight: 400;
  font-size: ${({ $size }) =>
    rem(
      $size === "s" ? 12 : $size === "m" ? 16 : $size === "l" ? 20 : $size || 14
    )};
`;

export default Description;

import { createUploadLink } from "shared/providers/apollo/apollo-upload.package";

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GQL_URL,
  headers: {
    "Apollo-Require-Preflight": "true",
  },
});

export default httpLink;

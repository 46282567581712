import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import { portfolioVar } from "../common/apollo/vars/portfolio.var";

import { LoggedInUserFieldsFragment, useMeQuery } from "../generated/graphql";

import { WEB_AUTH_TOKEN } from "shared/common/data/constants";

import { getLocalStorageItem } from "shared/utils/local-storage";

export interface IAuthUser {
  user?: LoggedInUserFieldsFragment;
  loading?: boolean;
  refetch: () => void;
}

/**
 * Get current logged user
 * @returns logged user object
 */

const useAuth = (): IAuthUser => {
  const portfolio = useReactiveVar(portfolioVar);
  const token = getLocalStorageItem(WEB_AUTH_TOKEN);

  const { data, loading, refetch } = useMeQuery({
    skip: !portfolio?.id || !token,
    variables: {
      portfolioId: portfolio?.id as string,
    },
  });

  return {
    user: data?.me,
    loading,
    refetch,
  };
};

export default useAuth;

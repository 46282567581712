import { makeVar } from "shared/providers/apollo/apollo.package";
import {
  AllRolePermissionsFragment,
  UserStatuses,
} from "../../../generated/graphql";
import { IFile } from "shared/common/interfaces/file.interfaces";

export interface IUserPropertyDataVar {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: UserStatuses;
  title: string | null;
  phone: string | null;
  photo: IFile | null;
  role: {
    id: string;
    title: string;
    permissions: AllRolePermissionsFragment;
  };
  printer: {
    id: string;
    title: string;
  } | null;
  homepageId: string | null;
}

/**
 * Store user data of currently selected property
 */
export const userPropertyDataVar = makeVar<IUserPropertyDataVar | null>(null);

export const resetUserPropertyDataVar = () => userPropertyDataVar(null);

import styled from "styled-components";
import rem from "../../utils/rem";

const MaintenanceWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 15px;

  .maintenance-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 90%;

    > img {
      max-width: 150px;
    }

    > h1 {
      font-weight: 600;
      margin: 20px;
    }

    > p {
      color: #6b7680;
      margin-bottom: 16px;
      font-size: ${rem(14)};

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export default MaintenanceWrapper;

import { forwardRef } from "react";

import ButtonWrapper from "./button.styles";

import { TbLoader2 } from "react-icons/tb";

import { IButtonProps } from "./button.interfaces";

const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      type = "button",
      variant = "primary",
      $size = "medium",
      disabled,
      isLoading,
      prependElement,
      appendElement,
      children,
      ...rest
    },
    ref
  ) => {
    return (
      <ButtonWrapper
        ref={ref}
        type={type}
        variant={variant}
        $size={$size}
        disabled={!!disabled || !!isLoading}
        {...rest}
      >
        {isLoading ? (
          <span className="button-spinner-wrapper">
            <TbLoader2 className="rotate-animation" />
          </span>
        ) : prependElement ? (
          <span className="button-prepend-wrapper">{prependElement}</span>
        ) : null}

        {children && <span className="button-text-wrapper">{children}</span>}

        {appendElement && (
          <span className="button-append-wrapper">{appendElement}</span>
        )}
      </ButtonWrapper>
    );
  }
);

Button.displayName = "Button";

export default Button;

import { Fragment, PropsWithChildren } from "react";

import "react-toastify/dist/ReactToastify.min.css";
import "react-contexify/dist/ReactContexify.css";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.min.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const StylesProvider = ({ children }: PropsWithChildren) => {
  return <Fragment>{children}</Fragment>;
};

export default StylesProvider;

export const WEB_AUTH_TOKEN = "bhAuthToken";
export const WEB_AUTH_PROPERTY = "bhWebAuthProperty";

// Admin
export const WEB_AUTH_ADMIN_TOKEN = "bhAdminAuthToken";

/**
 * Breakpoints for responsive design
 */

export const BREAKPOINTS = {
  sm: "576px",
  md: "768px",
  lg: "992px", // default
  xl: "1200px",
  xxl: "1600px",
};

/**
 * Different types for DND depending on the usage (table, menu manager, etc..)
 */

export const DndTypes = {
  COLUMN_SELECTOR: "columnSelector",
  SINGLE_MENU_ITEM: "singleMenuItem",
  SINGLE_STACKING_PLAN_OVERVIEW_ITEM: "singleStackingPlanOverviewItem",
};

/**
 * Persistent pageSize localStorage key and event name
 */

export const DEFAULT_PAGE_SIZE = 10;
export const PERSISTENT_PAGE_SIZE = "bhPageSize";

/**
 * react-select global className
 */

export const SELECT_CLASS_NAME = "form-custom-select-element";

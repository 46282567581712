import { getMainDefinition } from "shared/providers/apollo/apollo-utilities.package";
import { ApolloLink, split } from "shared/providers/apollo/apollo.package";

import authLink from "./auth-link";
import httpLink from "./http-link";
import wsLink from "./ws-link";

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  ApolloLink.from([authLink, httpLink])
);

export default splitLink;

import SpinnerWrapper from "./spinner.styles";

import { ISpinnerProps } from "./spinner.interfaces";

const Spinner = ({ children, ...props }: ISpinnerProps) => {
  return (
    <SpinnerWrapper {...props}>
      <div className="spinner-element" />

      {children ? <span>{children}</span> : null}
    </SpinnerWrapper>
  );
};

export default Spinner;

import { makeVar } from "shared/providers/apollo/apollo.package";
import { GlobalModalTypesEnum } from "../../enums/global-modal-types.enum";

interface IDockVar {
  title: string;
  id: string;
  type: GlobalModalTypesEnum;
}

/**
 * Controls minimized dock tray
 */

export const dockRightPositionVar = makeVar<string | null>(null);

export const dockVar = makeVar<IDockVar[]>([]);

export const resetDockRightPositionVar = () => dockRightPositionVar(null);

export const resetDockVar = () => dockVar([]);

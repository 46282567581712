import { Helmet as HelmetPackage } from "react-helmet-async";

import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import { portfolioVar } from "../../common/apollo/vars/portfolio.var";
import { propertyDataVar } from "../../common/apollo/vars/property-data.var";

const Helmet = () => {
  const portfolio = useReactiveVar(portfolioVar);
  const propertyData = useReactiveVar(propertyDataVar);

  return (
    <HelmetPackage>
      <title>{propertyData?.title || portfolio?.title || "Building Hub"}</title>

      {propertyData?.favicon?.url ? (
        <link rel="icon" href={propertyData.favicon.url} type="image/png" />
      ) : (
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
      )}
    </HelmetPackage>
  );
};

export default Helmet;

import styled, { css } from "styled-components";

import { IButtonProps } from "./button.interfaces";

import rem from "../../utils/rem";

const ButtonWrapper = styled.button<IButtonProps>`
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: ${({ $height }) => ($height ? $height : "auto")};
  min-height: ${({ $size, theme }) => theme.heights[$size || "medium"]};
  width: ${({ $width, $size, isBlock, iconOnly, theme }) =>
    $width
      ? $width
      : isBlock
      ? "100%"
      : iconOnly
      ? theme.heights[$size || "medium"]
      : "auto"};
  border: 1px solid
    ${({ $borderColor, variant, theme }) =>
      $borderColor
        ? $borderColor
        : variant === "secondary"
        ? theme.colors.grey[300]
        : variant === "tertiary"
        ? "transparent"
        : variant === "warning"
        ? theme.colors.warning.base
        : variant === "success"
        ? theme.colors.success.base
        : theme.colors.primary.base};
  border-radius: ${({ $borderRadius, theme }) =>
    $borderRadius || theme.borderRadiusMd};
  color: ${({ $color, variant, theme }) =>
    $color
      ? $color
      : variant === "secondary" || variant === "tertiary"
      ? theme.colors.grey[800]
      : "#ffffff"};
  padding: ${({ $size, iconOnly }) =>
    iconOnly
      ? "0"
      : $size === "large"
      ? "12.5px 16px"
      : $size === "medium"
      ? "8.5px 12px"
      : "6px 10px"};
  font-family: ${({ theme }) => theme.typography.font}, sans-serif;
  font-size: ${({ $size }) => rem($size === "small" ? 12 : 14)};
  font-weight: 400;
  background-color: ${({ $bgColor, variant, theme }) =>
    $bgColor
      ? $bgColor
      : variant === "secondary" || variant === "tertiary"
      ? "#ffffff"
      : variant === "warning"
      ? theme.colors.warning.base
      : variant === "success"
      ? theme.colors.success.base
      : theme.colors.primary.base};
  box-shadow: none;
  outline: none;
  appearance: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: ${({ $hoverColor, variant, theme }) =>
      $hoverColor
        ? $hoverColor
        : variant === "secondary" || variant === "tertiary"
        ? theme.colors.grey[800]
        : "#ffffff"};
    background-color: ${({ $hoverBgColor, variant, theme }) =>
      $hoverBgColor
        ? $hoverBgColor
        : variant === "secondary"
        ? theme.colors.grey[50]
        : variant === "tertiary"
        ? theme.colors.grey[100]
        : variant === "warning"
        ? theme.colors.warning[600]
        : variant === "success"
        ? theme.colors.success[600]
        : theme.colors.primary[600]};
    border-color: ${({ $hoverBorderColor, variant, theme }) =>
      $hoverBorderColor
        ? $hoverBorderColor
        : variant === "secondary"
        ? theme.colors.grey[300]
        : variant === "tertiary"
        ? theme.colors.grey[100]
        : variant === "warning"
        ? theme.colors.warning[600]
        : variant === "success"
        ? theme.colors.success[600]
        : theme.colors.primary[600]};
  }

  &:active,
  &:focus {
    color: ${({ $hoverColor, variant, theme }) =>
      $hoverColor
        ? $hoverColor
        : variant === "secondary" || variant === "tertiary"
        ? theme.colors.grey[800]
        : "#ffffff"};
    background-color: ${({ $hoverBgColor, variant, theme }) =>
      $hoverBgColor
        ? $hoverBgColor
        : variant === "secondary"
        ? theme.colors.grey[50]
        : variant === "tertiary"
        ? theme.colors.grey[100]
        : variant === "warning"
        ? theme.colors.warning[900]
        : variant === "success"
        ? theme.colors.success[900]
        : theme.colors.primary[900]};
    border-color: ${({ $hoverBorderColor, variant, theme }) =>
      $hoverBorderColor
        ? $hoverBorderColor
        : variant === "secondary"
        ? theme.colors.grey[50]
        : variant === "tertiary"
        ? theme.colors.grey[400]
        : variant === "warning"
        ? theme.colors.warning[900]
        : variant === "success"
        ? theme.colors.success[900]
        : theme.colors.primary[900]};

    ${({ variant, theme }) =>
      variant === "primary"
        ? css`
            outline: 3px solid ${theme.colors.grey[200]};
          `
        : ""}

    ${({ variant, theme }) =>
      variant === "secondary"
        ? css`
            outline: 4px solid ${theme.colors.primary[100]};
          `
        : ""}
  }

  ${({ iconOnly, iconOnlyMobile, $size, $width, isBlock, theme }) =>
    !iconOnly && iconOnlyMobile
      ? css`
          ${theme.breakpoints.down("sm")} {
            padding: 0;
            width: ${$width
              ? $width
              : isBlock
              ? "100%"
              : theme.heights[$size || "medium"]};
          }
        `
      : undefined}

  ${({ hidePrependElementMobile, theme }) =>
    hidePrependElementMobile
      ? css`
          ${theme.breakpoints.down("sm")} {
            .button-prepend-wrapper {
              display: none;
            }
          }
        `
      : undefined}

  ${({ hideTextMobile, theme }) =>
    hideTextMobile
      ? css`
          ${theme.breakpoints.down("sm")} {
            .button-text-wrapper {
              display: none;
            }
          }
        `
      : undefined}

  ${({ hideAppendElementMobile, theme }) =>
    hideAppendElementMobile
      ? css`
          ${theme.breakpoints.down("sm")} {
            .button-append-wrapper {
              display: none;
            }
          }
        `
      : undefined}

  strong {
    color: inherit;
  }

  > span {
    color: inherit;

    + span {
      margin-left: 7px;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  img,
  svg {
    display: block;
    width: calc(
      (
          ${({ $size, theme }) =>
            $size
              ? theme.heights[$size]
                ? theme.heights[$size]
                : $size
              : theme.heights.medium}
        ) / 2
    );
    max-height: calc(
      (
          ${({ $size, theme }) =>
            $size
              ? theme.heights[$size]
                ? theme.heights[$size]
                : $size
              : theme.heights.medium}
        ) / 2
    );
  }
`;

export default ButtonWrapper;

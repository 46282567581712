import { IFile } from "shared/common/interfaces/file.interfaces";
import { makeVar } from "shared/providers/apollo/apollo.package";

export interface IPortfolioVar {
  id: string;
  title: string;
  url: string;
  logo: IFile | null;
  background: IFile | null;
}

export const portfolioVar = makeVar<IPortfolioVar | null>(null);

import { PropsWithChildren } from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";

interface IStyledComponentsProvider {
  theme: DefaultTheme;
}

const StyledThemeProvider = ({
  theme,
  children,
}: PropsWithChildren<IStyledComponentsProvider>) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default StyledThemeProvider;

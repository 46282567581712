import { css } from "styled-components";
import rem from "../../utils/rem";

export const togglerBgStyles = css`
  border: 1px solid transparent;

  &.open {
    background-color: ${({ theme }) => theme.colors.grey[100]};
    border-color: ${({ theme }) => theme.colors.grey[300]};
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.colors.grey[100]};
    border-color: ${({ theme }) => theme.colors.grey[300]};
  }
`;

export const dropdownItemStyles = css`
  padding: 8px;
  display: flex;
  align-items: center;
  color: #000000;
  border-radius: 8px;
  font-size: ${rem(14)};
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 4px;
  line-height: 1.4;

  > svg {
    font-size: ${rem(16)};
    margin-right: 12px;
    flex-shrink: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const dropdownItemHoverActiveStyles = css`
  background-color: ${({ theme }) => theme.colors.grey[100]};
`;

export const dropdownItemDisabledStyles = css`
  color: ${({ theme }) => theme.colors.grey[500]};
  cursor: not-allowed;
`;

export const editDeleteIconsStyles = (hidden?: boolean) => css`
  margin-left: 10px;
  cursor: pointer;
  opacity: ${hidden ? "0" : undefined};
  visibility: ${hidden ? "hidden" : undefined};
  transition: color 0.2s ease;

  &--edit-toggler {
    &:hover,
    &:active,
    &:focus {
      color: ${({ theme }) => theme.colors.primary.base};
    }
  }

  &--deleter {
    &:hover,
    &:active,
    &:focus {
      color: ${({ theme }) => theme.colors.warning.base};
    }
  }
`;

import { createClient } from "graphql-ws";
import { GraphQLWsLink } from "shared/providers/apollo/link/subscriptions";

import { propertyIdVar } from "./vars/property-id.var";

import { WEB_AUTH_TOKEN } from "shared/common/data/constants";

import { getLocalStorageItem } from "shared/utils/local-storage";

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GQL_WS_URL || "",
    keepAlive: 10_000,
    connectionParams: () => {
      const token = getLocalStorageItem(WEB_AUTH_TOKEN);

      // Lowercase because headers are going to be lowercase anyways
      const propertyid = propertyIdVar();

      return {
        ...(token ? { authorization: `Bearer ${token}` } : {}),
        ...(propertyid ? { propertyid } : {}),
      };
    },
  })
);

export default wsLink;

import { Fragment } from "react";
import { Redirect, useLocation } from "react-router-dom";

import Spinner from "shared/components/spinner/spinner.component";
import UserInactive from "shared/components/user-inactive/user-inactive.component";

import { IProtectedRoute } from "./protected-route.interfaces";

const ProtectedRoute = ({
  loggedIn,
  loading,
  inactive,
  children,
}: IProtectedRoute) => {
  const location = useLocation();

  if (loading) {
    return <Spinner $height="100vh" />;
  }

  if (loggedIn) {
    if (inactive) {
      return <UserInactive />;
    } else {
      return <Fragment>{children}</Fragment>;
    }
  }

  return (
    <Redirect
      to={{
        pathname: "/login",
        state: {
          from: location,
        },
      }}
    />
  );
};

export default ProtectedRoute;

import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

import Title from "../title/title.styles";
import Description from "../description/description.styles";
import Button from "../button/button.component";

import NotFoundWrapper from "./not-found.styles";

import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as NotFoundLogo } from "../../assets/404-logo.svg";

import { INotFoundProps } from "./not-found.interfaces";

const NotFound = ({
  url,
  title,
  text,
  buttonText,
  buttonOnClick,
  hideButton,
  insideLayout,
}: INotFoundProps) => {
  const history = useHistory();

  return (
    <NotFoundWrapper insideLayout={insideLayout}>
      <div className="logo-wrapper">
        <NotFoundLogo />
      </div>

      <div className="text-wrapper">
        <Title className="mb-12">
          {title ? title : "You found a place that doesn't exist!"}
        </Title>

        <Description>
          {text ? text : "Hopefully it will be back soon."}
        </Description>
      </div>

      {hideButton ? null : (
        <Button
          $size="large"
          variant="primary"
          className="mb-12"
          onClick={
            buttonOnClick ? buttonOnClick : () => history.push(url || "/login")
          }
        >
          {buttonText || "Go to homepage"}
        </Button>
      )}

      <div className="footer-wrapper">
        <Logo />
        {` © ${dayjs().format("YYYY")} BuildingHub, All rights reserved`}
      </div>
    </NotFoundWrapper>
  );
};

export default NotFound;

import { Suspense } from "react";

import Spinner from "../../components/spinner/spinner.component";
import SpinnerModal from "../../containers/spinner-modal/spinner-modal.container";

// object vs Record<string, unknown>: https://stackoverflow.com/a/66606826

const withSuspense = <T extends object>(
  WrapperComponent: React.ComponentType<T>,
  spinnerAsModal?: boolean,
  spinnerHeight?: string
) => {
  const ComponentWithSuspense = (props: T) => {
    return (
      <Suspense
        fallback={
          spinnerAsModal ? (
            <SpinnerModal />
          ) : (
            <Spinner $height={spinnerHeight} />
          )
        }
      >
        <WrapperComponent {...props} />
      </Suspense>
    );
  };

  return ComponentWithSuspense;
};

export default withSuspense;

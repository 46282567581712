import Description from "../description/description.styles";
import Title from "../title/title.styles";
import Separator from "../separator/separator.styles";
import ExternalLink from "../external-link/external-link.styles";

import UserInactiveWrapper from "./user-inactive.styles";

const UserInactive = () => {
  return (
    <UserInactiveWrapper>
      <div className="inactive-wrapper">
        <div className="inactive-wrapper__content">
          <div className="inactive-wrapper__content__block">
            <Title level={3} className="mb-10">
              Your account is inactive!
            </Title>

            <Description>
              Please contact administrators for more information about your
              account.
            </Description>
          </div>

          <Separator />

          <div className="inactive-wrapper__content__block">
            <ExternalLink href="mailto:support@practicebc.com" target="_blank">
              Contact Support
            </ExternalLink>
          </div>
        </div>
      </div>
    </UserInactiveWrapper>
  );
};

export default UserInactive;

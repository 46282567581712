import { IFile } from "shared/common/interfaces/file.interfaces";
import { makeVar } from "shared/providers/apollo/apollo.package";

export interface IPropertyDataVar {
  id: string;
  title: string;
  timezone: string;
  primaryColor: string | null;
  bgImg: IFile | null;
  logo: IFile | null;
  favicon: IFile | null;
}

/**
 * Store currently selected property data
 */
export const propertyDataVar = makeVar<IPropertyDataVar | null>(null);

export const resetPropertyDataVar = () => propertyDataVar(null);

import MaintenanceWrapper from "./maintenance.styles";

import logo from "../../assets/logo.svg";

const Maintenance = () => {
  return (
    <MaintenanceWrapper>
      <div className="maintenance-container">
        <img src={logo} alt="BuildingHub Logo" />

        <h1>Unable to connect</h1>

        <p>It looks like you’re having trouble reaching us.</p>

        <p>
          This could be due to a temporary network issue. <br /> Please try
          refreshing the page or checking your internet connection. <br /> If
          the problem persists, please reach out to our support team at{" "}
          <a href="mailto:support@buildinghub.io">support@buildinghub.io</a>
        </p>
      </div>
    </MaintenanceWrapper>
  );
};

export default Maintenance;

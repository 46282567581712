import { makeVar } from "shared/providers/apollo/apollo.package";
import { GlobalModalTypesEnum } from "../../enums/global-modal-types.enum";

interface IGlobalModalVar {
  id: string;
  type: GlobalModalTypesEnum;
}

/**
 * Open global modal
 */

export const globalModalVar = makeVar<IGlobalModalVar | null>(null);

export const resetGlobalModalVar = () => globalModalVar(null);

import { PropsWithChildren } from "react";
import useAuth from "../../hooks/useAuth.hook";

import SharedProtectedRoute from "shared/hoc/protected-route/protected-route.hoc";

const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const { user, loading } = useAuth();

  return (
    <SharedProtectedRoute loggedIn={!!user} loading={!!loading}>
      {children}
    </SharedProtectedRoute>
  );
};

export default ProtectedRoute;

export const getLocalStorageItem = (key: string) => {
  const token = window.localStorage.getItem(key);

  if (token) {
    return token;
  } else {
    return null;
  }
};

export const setLocalStorageItem = (key: string, token: string) =>
  window.localStorage.setItem(key, token);

export const removeLocalStorageItem = (key: string) =>
  window.localStorage.removeItem(key);

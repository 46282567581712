import { DefaultTheme } from "styled-components";
import { BREAKPOINTS } from "../../common/data/constants";

/**
 * Keeping styled-components ts module here instead of common files because of the readability
 */

declare module "styled-components" {
  export interface ColorPallete {
    base: string;
    "50"?: string;
    "100"?: string;
    "200"?: string;
    "300"?: string;
    "400"?: string;
    "500"?: string;
    "600"?: string;
    "650"?: string;
    "700"?: string;
    "800"?: string;
    "900"?: string;
  }

  export interface DefaultTheme {
    boxShadow: string;
    boxShadowSecondary: string;
    boxShadowDropdown: string;
    borderRadius: string;
    borderRadiusMd: string;
    borderRadiusLg: string;
    heights: {
      small: string;
      medium: string;
      large: string;
    };
    layout: {
      background: string;
      collapsedWidth: string;
      expandedWidth: string;
      contentXPadding: string;
      contentBottomPadding: string;
      topbarHeight: string;
      topbarBottomMargin: string;
    };
    colors: {
      primary: ColorPallete;
      secondary: ColorPallete;
      accent: ColorPallete;
      grey: ColorPallete;
      blue: ColorPallete;
      success: ColorPallete;
      warning: ColorPallete;
      orange: ColorPallete;
      emeraldGreen: ColorPallete;
    };
    typography: {
      font: string;
      light: string;
      regular: string;
      medium: string;
      bold: string;
      h1: string;
      h2: string;
      h3: string;
      h4: string;
      h5: string;
      h6: string;
    };
    breakpoints: {
      up: (breakpoint?: keyof typeof BREAKPOINTS) => string;
      down: (breakpoint?: keyof typeof BREAKPOINTS) => string;
      between: (
        breakpoint1?: keyof typeof BREAKPOINTS,
        breakpoint2?: keyof typeof BREAKPOINTS
      ) => string;
      only: (breakpoint?: keyof typeof BREAKPOINTS) => string;
    };
    zIndex: {
      stickyTop: string;
      stickyFooter: string;
      modal: string;
      sidebar: string;
      popouts: string;
      textEditorBar: string;
      lightbox: string;
      selectPortal: string;
    };
  }
}

function up(breakpoint?: keyof typeof BREAKPOINTS) {
  const size = BREAKPOINTS[breakpoint || "lg"];
  return `@media screen and (min-width: ${size})`;
}

function down(breakpoint?: keyof typeof BREAKPOINTS) {
  const size = BREAKPOINTS[breakpoint || "lg"];
  return `@media screen and (max-width: calc(${size} - 1px))`;
}

function between(
  breakpoint1?: keyof typeof BREAKPOINTS,
  breakpoint2?: keyof typeof BREAKPOINTS
) {
  const size1 = BREAKPOINTS[breakpoint1 || "lg"];
  const size2 = BREAKPOINTS[breakpoint2 || "lg"];
  if (breakpoint2) {
    return `@media screen and (min-width: ${size1}) and (max-width: ${size2})`;
  }
  return `@media screen and (max-width: ${size1})`;
}

function only(breakpoint?: keyof typeof BREAKPOINTS) {
  switch (breakpoint) {
    case "sm":
      return between("sm", "md");
    case "md":
      return between("md", "lg");
    case "lg":
      return between("lg", "xl");
    case "xl":
      return between("xl", "xxl");
    case "xxl":
      return between("xxl");
    default:
      return between("md", "lg");
  }
}

const theme: DefaultTheme = {
  boxShadow:
    "0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 10px rgba(0, 0, 0, 0.06)",
  boxShadowSecondary:
    "0px 4px 15px rgba(45, 45, 45, 0.05), 0px 4px 24px rgba(45, 45, 45, 0.02)",
  boxShadowDropdown:
    "0px 6px 44px #eaeaea, 0px 3px 3px rgba(0, 0, 0, 0.05), 0px 6px 25px rgba(0, 0, 0, 0.06)",
  borderRadius: "6px",
  borderRadiusMd: "8px",
  borderRadiusLg: "10px",
  heights: {
    small: "32px",
    medium: "40px",
    large: "48px",
  },
  layout: {
    background: "#FCFCFC",
    collapsedWidth: "80px",
    expandedWidth: "258px",
    contentXPadding: "16px",
    contentBottomPadding: "24px",
    topbarHeight: "76px",
    topbarBottomMargin: "16px",
  },
  colors: {
    primary: {
      base: "#1C7ED6",
      "50": "#E7F5FF",
      "100": "#D0EBFF",
      "600": "#228BE6",
      "700": "#1C7ED6",
      "900": "#1864AB",
    },
    secondary: {
      base: "#1098AD",
      "50": "#E3FAFC",
      "100": "#C5F6FA",
      "600": "#15AABF",
      "700": "#1098AD",
      "900": "#0B7285",
    },
    accent: {
      base: "#F59F00",
      "50": "#FFF9DB",
      "100": "#FFF3BF",
      "200": "#FFEC99",
      "600": "#FAB005",
      "700": "#F59F00",
      "900": "#E67700",
    },
    grey: {
      base: "#4C5761",
      "50": "#FAFAFA",
      "100": "#F5F6F7",
      "200": "#EDF1F5",
      "300": "#E6EBF0",
      "400": "#D5DCE3",
      "500": "#B7BFC7",
      "600": "#879099",
      "650": "#6B7680",
      "700": "#4C5761",
      "800": "#273038",
      "900": "#0A121A",
    },
    blue: {
      base: "#1C7ED6",
      "50": "#E7F5FF",
      "100": "#D0EBFF",
      "600": "#228BE6",
      "700": "#1C7ED6",
      "900": "#1864AB",
    },
    success: {
      base: "#37B24D",
      "50": "#EBFBEE",
      "100": "#D3F9D8",
      "200": "#B2F2BB",
      "600": "#40C057",
      "700": "#37B24D",
      "900": "#2B8A3E",
    },
    warning: {
      base: "#F03E3E",
      "50": "#FFF5F5",
      "100": "#FFE3E3",
      "200": "#FFC9C9",
      "600": "#FA5252",
      "700": "#F03E3E",
      "900": "#C92A2A",
    },
    orange: {
      base: "#F59F00",
      "50": "#FFF9DB",
      "100": "#FFF3BF",
      "200": "#FFEC99",
      "600": "#FAB005",
      "700": "#F59F00",
      "900": "#E67700",
    },
    emeraldGreen: {
      base: "#006633",
      "50": "#F3FFF9",
      "100": "#E5FFF2",
      "200": "#B3FED8",
      "300": "#81F0B8",
      "400": "#64D19B",
      "500": "#56C28C",
      "600": "#1D8551",
      "650": "#117141",
      "700": "#006633",
      "800": "#005229",
      "900": "#003D1F",
    },
  },
  typography: {
    font: "Inter",
    light: "300",
    regular: "400",
    medium: "600",
    bold: "700",
    h1: "2rem",
    h2: "1.75rem",
    h3: "1.5rem",
    h4: "1.25rem",
    h5: "1rem",
    h6: "0.875rem",
  },
  breakpoints: {
    up,
    down,
    between,
    only,
  },
  zIndex: {
    lightbox: "7",
    selectPortal: "6",
    modal: "5",
    sidebar: "4",
    popouts: "3",
    stickyTop: "2",
    stickyFooter: "2",
    textEditorBar: "1",
  },
};

export default theme;

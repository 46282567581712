import { makeVar } from "shared/providers/apollo/apollo.package";

import theme from "shared/styles/Theme";

import { DefaultTheme } from "shared/providers/styled-components/styled-components.package";

/**
 * Store and update current theme anywhere in the app
 */

export const themeVar = makeVar<DefaultTheme>(theme);

import { SingleValue } from "react-select";
import { IPrimaryColorOption } from "./primary-color-select.interfaces";

export const primaryColorOptions: IPrimaryColorOption[] = [
  {
    value: "blue",
    label: "Blue",
    pallete: {
      base: "#1C7ED6",
      "50": "#E7F5FF",
      "100": "#D0EBFF",
      "600": "#228BE6",
      "700": "#1C7ED6",
      "900": "#1864AB",
    },
  },
  {
    value: "red",
    label: "Red",
    pallete: {
      base: "#E42F3C",
      "50": "#FAE5E6",
      "100": "#F4BDC1",
      "600": "#F2626C",
      "700": "#E42F3C",
      "900": "#BE1E2A",
    },
  },
  {
    value: "green",
    label: "Green",
    pallete: {
      base: "#06A072",
      "50": "#E6FCF5",
      "100": "#C3FAE8",
      "600": "#19C893",
      "700": "#06A072",
      "900": "#087F57",
    },
  },
  {
    value: "emeraldGreen",
    label: "Emerald Green",
    pallete: {
      base: "#006633",
      "50": "#F3FFF9",
      "100": "#E5FFF2",
      "600": "#1D8551",
      "700": "#006633",
      "900": "#003D1F",
    },
  },
  {
    value: "grape",
    label: "Grape",
    pallete: {
      base: "#AE3EC9",
      "50": "#F8F0FC",
      "100": "#F3D9FA",
      "600": "#C162DE",
      "700": "#AE3EC9",
      "900": "#862E9C",
    },
  },
  {
    value: "violet",
    label: "Violet",
    pallete: {
      base: "#7048E8",
      "50": "#F3F0FF",
      "100": "#E5DBFF",
      "600": "#9977FC",
      "700": "#7048E8",
      "900": "#5B39C0",
    },
  },
  {
    value: "orange",
    label: "Orange",
    pallete: {
      base: "#F76707",
      "50": "#FFF4E6",
      "100": "#FFE8CC",
      "600": "#FF8117",
      "700": "#F76707",
      "900": "#CF3E05",
    },
  },
];

export const getPrimaryColorOption = (
  color?: string | null,
  strict?: boolean
): SingleValue<IPrimaryColorOption> =>
  color
    ? primaryColorOptions.find(({ value }) => value === color) ||
      (strict
        ? null
        : {
            value: color,
            label: color,
          })
    : null;

import styled from "styled-components";

import { ISpinnerProps } from "./spinner.interfaces";

import rem from "../../utils/rem";

const SpinnerWrapper = styled.div<ISpinnerProps>`
  height: ${({ $height, theme }) =>
    $height === "full-layout"
      ? `calc(100vh - (${theme.layout.topbarHeight} + ${theme.layout.topbarBottomMargin} + ${theme.layout.contentBottomPadding}))`
      : $height};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > span {
    margin-top: 15px;
    font-size: ${rem(15)};
    font-weight: 500;
    color: ${({ theme }) => theme?.colors?.grey[600] || "#C6CAD6"};
  }

  .spinner-element {
    display: inline-block;

    &::after {
      content: " ";
      display: block;
      width: ${({ $size }) => $size || "50px"};
      height: ${({ $size }) => $size || "50px"};
      border-radius: 50%;
      border-width: 6px;
      border-style: solid;
      border-color: ${({ theme }) => theme?.colors?.primary.base || "#1C7ED6"}
        transparent ${({ theme }) => theme?.colors?.primary.base || "#1C7ED6"}
        transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }

    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

export default SpinnerWrapper;

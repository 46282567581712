import styled from "styled-components";

import { INotFoundProps } from "./not-found.interfaces";

import bgImg from "../../assets/404-bg.png";

const NotFoundWrapper = styled.div<Pick<INotFoundProps, "insideLayout">>`
  height: ${({ insideLayout, theme }) =>
    insideLayout
      ? `calc(100vh - (${theme.layout.topbarHeight} + ${theme.layout.topbarBottomMargin} + ${theme.layout.contentBottomPadding}))`
      : "100vh"};
  padding: ${({ insideLayout }) => (insideLayout ? "" : "8% 9% 40px")};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: url(${bgImg}) right bottom no-repeat;

  .logo-wrapper {
    margin-bottom: 40px;
    > svg {
      width: 200px;
      max-width: 100%;
    }
  }

  .text-wrapper {
    margin-bottom: 40px;
  }

  .footer-wrapper {
    margin-top: auto;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.grey[600]};
    font-size: 12px;
    font-weight: 400;

    > svg {
      width: 165px;
      max-width: 100%;
      margin-right: 20px;
    }
  }
`;

export default NotFoundWrapper;

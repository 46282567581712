import styled from "styled-components";

import { ITitleProps } from "./title.interfaces";

const Title = styled.h1<ITitleProps>`
  display: flex;
  align-items: center;
  font-size: ${({ level, theme }) =>
    level
      ? level === 1
        ? theme.typography.h1
        : level === 2
        ? theme.typography.h2
        : level === 3
        ? theme.typography.h3
        : level === 4
        ? theme.typography.h4
        : level === 5
        ? theme.typography.h5
        : theme.typography.h6
      : theme.typography.h1};

  > svg {
    color: ${({ theme }) => theme.colors.primary.base};
    margin-right: 7px;
  }
`;

export default Title;
